import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import styles from "./InputWithButton.module.css";

export function InputWithButton({
  placeholder,
  buttonText,
  onChange,
  value,
  onSubmit,
  isPhoneInput = false,
}: {
  placeholder: string;
  buttonText: string;
  onChange: (value: string) => void;
  value: string;
  onSubmit: () => void;
  isPhoneInput: boolean;
}) {
  return (
    <div className={styles.inputContainer}>
      {isPhoneInput ? (
        /* Phone Input Field */
        <PhoneInput
          country={"ru"} // Default country
          value={value}
          onChange={(phone) => onChange(phone)}
          inputClass={styles.inputPhone}
          placeholder={placeholder}
          specialLabel=""
          dropdownClass={styles.phoneDropdown} /* Custom dropdown styling */
          buttonClass={
            styles.flagDropdownButton
          } /* Custom styling for dropdown button */
        />
      ) : (
        /* Standard Input Field */
        <input
          type="text"
          value={value}
          onChange={(e) => onChange(e.target.value)}
          className={styles.inputField}
          placeholder={placeholder}
        />
      )}

      <button className={styles.continueButton} onClick={() => onSubmit()}>
        {buttonText}
      </button>
    </div>
  );
}
