// src/features/CarFilters/ui/CarFilters.tsx

import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useCarFiltersData } from "../hooks/useCarFiltersData";
import styles from "./CarFilters.module.css";
import { Selector } from "shared/ui/Selector";
import { MultiSelect } from "shared/ui/MultiSelect";
import { RangeInput } from "shared/ui/RangeInput";
import { ListOfTypes } from "shared/ui/ListOfTypes/ui/ListOfTypes";
import { Checkbox } from "shared/ui/Checkbox/ui/Checkbox";
import { Button } from "shared/ui/button/ui/Button";
import { OrangeArrowDown } from "shared/ui/Icons/OrangeArrowDown";
import {
  bodyTypes,
  transmissions,
  fuelTypes,
  driveTypes,
  types,
  users,
  sellers,
  radius,
  lastAdded,
  countryCodeToName,
  nameToCountryCode,
} from "../model/constants";
import { useOutsideClick } from "shared/ui/Selector/hooks/useOutsideClick";
import { Input } from "shared/ui/input/ui/Input";
import { getCarsCount } from "../lib/request";

import { FilterData } from "../model/interfaces";
import useAddressStore, { AddressDataType } from "store/useAdressStore";

export interface CarFiltersProps {
  setFilters: Dispatch<SetStateAction<FilterData | null>>;
  isMarket?: boolean;
  selectedRegions?: string[];
  onCarsCountChange?: (count: number | null) => void;
}

export default function CarFilters({
  setFilters,
  isMarket = false,
  selectedRegions = [],
  onCarsCountChange,
}: CarFiltersProps) {
  const { t } = useTranslation();

  const {
    filterData,
    hasFilterChanged,
    savedFilters,
    brandsList,
    getModelsByBrand,
    handlers,
  } = useCarFiltersData();
  const { addresses } = useAddressStore();

  const [isShowingSaveDialog, setIsShowingSaveDialog] = useState(false);
  const [newFilterName, setNewFilterName] = useState("");
  const [isSavedFiltersOpen, setIsSavedFiltersOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [carsCount, setCarsCount] = useState<number | null>(null);
  const [isCountLoading, setIsCountLoading] = useState(false);

  const saveFilterRef = useRef<HTMLDivElement>(null);

  const handleSubmit = () => {
    setFilters(filterData);
  };

  const handleSaveFilter = () => {
    if (newFilterName.trim()) {
      handlers.saveCurrentFilters(newFilterName.trim());
      setNewFilterName("");
      setIsShowingSaveDialog(false);
    }
  };

  useOutsideClick(saveFilterRef, () => {
    setIsShowingSaveDialog(false);
  });

  const updateCarsCount = useCallback(async () => {
    if (!hasFilterChanged) return;

    try {
      setIsCountLoading(true);
      const response: any = await getCarsCount(filterData);
      setCarsCount(response.count);
      if (onCarsCountChange) {
        onCarsCountChange(response.count);
      }
    } catch (error) {
      console.error("Error fetching cars count:", error);
      setCarsCount(null);
      if (onCarsCountChange) {
        onCarsCountChange(null);
      }
    } finally {
      setIsCountLoading(false);
    }
  }, [filterData, hasFilterChanged, onCarsCountChange]);

  useEffect(() => {
    localStorage.setItem("filterData", JSON.stringify(filterData));
    if (!isMarket && hasFilterChanged) {
      const debounceTimeout = setTimeout(() => {
        updateCarsCount();
      }, 500);

      return () => clearTimeout(debounceTimeout);
    }
  }, [filterData, hasFilterChanged]);

  useEffect(() => {
    if (selectedRegions.length > 0) {
      handlers.handleRegionsChange(selectedRegions);
    }
  }, [selectedRegions]);

  const isButtonDisabled = () => {
    if (isMarket) {
      return false;
    } else {
      return (
        isCountLoading ||
        (hasFilterChanged && (carsCount === null || carsCount === 0))
      );
    }
  };

  function getCountryNames(data: AddressDataType | null): string[] {
    if (!data) {
      return [];
    }
    return Object.keys(data).map(
      (code) => countryCodeToName[code] || "Unknown Country"
    );
  }

  function getCitiesBySelectedCountries(
    data: AddressDataType | null,
    selectedCountries: string[] | undefined
  ): string[] {
    if (!data || !selectedCountries || selectedCountries.length === 0) {
      return [];
    }

    const allCities = new Set<string>();

    for (const country of selectedCountries) {
      const countryCode = nameToCountryCode[country];
      const citiesOrRegions = data[countryCode];

      if (citiesOrRegions) {
        Object.keys(citiesOrRegions).forEach((city) => allCities.add(city));
      }
    }

    return Array.from(allCities);
  }

  return (
    <div className={styles.layout}>
      <div className={styles.container}>
        {/* Car Type and Checkboxes */}
        <div className={styles.row}>
          <div className={styles.rowElement}>
            <ListOfTypes
              values={Object.keys(types)}
              selected={filterData.data.carType}
              onSelect={handlers.handleTypeChange}
            />
          </div>
          <div className={styles.rowElement}>
            <Checkbox
              onChange={(e) =>
                handlers.handleCheckboxChange(
                  e.target.checked,
                  "isAccidentFree"
                )
              }
              checked={filterData.data.isAccidentFree}
              label={t("withNDS")}
              id="nds"
            />
            <Checkbox
              onChange={(e) =>
                handlers.handleCheckboxChange(e.target.checked, "isBelowMarket")
              }
              checked={filterData.data.isBelowMarket}
              label={t("underMarket")}
              id="under"
            />
            <Checkbox
              onChange={(e) =>
                handlers.handleCheckboxChange(e.target.checked, "isVat")
              }
              checked={filterData.data.isVat}
              label={t("noCrush")}
              id="crash"
            />
          </div>
        </div>
        {/* Brand Details Section */}
        <div className={styles.brandDetails}>
          {filterData.data.brand_details &&
          Array.isArray(filterData.data.brand_details) ? (
            filterData.data.brand_details.map((detail, index) => (
              <div key={index} className={styles.brandDetail}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 24,
                    width: "100%",
                  }}
                >
                  <Selector
                    label={t("mark")}
                    values={brandsList}
                    value={detail.brand}
                    onValue={(value) =>
                      handlers.handleBrandChange(value, index)
                    }
                  />
                  {!isMarket && index === 0 && (
                    <button
                      onClick={handlers.addBrandDetail}
                      className={styles.addButton}
                    >
                      +
                    </button>
                  )}
                </div>
                {isMarket ? (
                  <Selector
                    label={t("model")}
                    values={getModelsByBrand(detail.brand)}
                    disabled={!detail.brand}
                    onValue={(value) =>
                      handlers.handleModelsChange([value], index)
                    }
                  />
                ) : (
                  <MultiSelect
                    label={t("model")}
                    values={getModelsByBrand(detail.brand)}
                    selected={detail.models}
                    disabled={!detail.brand}
                    onSelected={(values) =>
                      handlers.handleModelsChange(values, index)
                    }
                  />
                )}
                <MultiSelect
                  label={t("generation")}
                  values={[]}
                  selected={detail.generation.map(String)}
                  disabled
                  onSelected={(values) =>
                    handlers.handleGenerationsChange(values.map(Number), index)
                  }
                />
                <Input
                  placeholder={t("useGTI")}
                  value={detail.custom_input}
                  onChange={(e) =>
                    handlers.handleCustomInputChange(e.target.value, index)
                  }
                />
                {filterData.data.brand_details.length > 1 && index !== 0 && (
                  <button
                    onClick={() => handlers.removeBrandDetail(index)}
                    className={styles.removeButton}
                  >
                    ×
                  </button>
                )}
              </div>
            ))
          ) : (
            <div>No brand details available.</div>
          )}
        </div>

        <div style={{ margin: "12px 0", borderTop: "2px dotted #e6e6e6" }} />
        {/* main filters */}
        <div className={styles.mainFilterContainer}>
          <div className={styles.row}>
            <div className={styles.rowElement}>
              <MultiSelect
                values={Object.keys(bodyTypes)}
                selected={filterData.data.carBody}
                onSelected={handlers.handleBodyTypeChange}
                label={t("body")}
              />
            </div>
            <div className={styles.rowElement}>
              <MultiSelect
                values={Object.keys(transmissions)}
                selected={filterData.data.transmission}
                onSelected={handlers.handleTransmissionChange}
                label={t("box")}
              />
            </div>
          </div>
          {/* Fuel and Drive Types */}
          <div className={styles.row}>
            <div className={styles.rowElement}>
              <Selector
                values={Object.values(fuelTypes)}
                value={filterData.data.fuelTypes}
                onValue={handlers.handleFuelTypeChange}
                label={t("engine")}
              />
            </div>
            <div className={styles.rowElement}>
              <Selector
                values={Object.values(driveTypes)}
                value={filterData.data.driveTypes}
                onValue={handlers.handleDriveTypeChange}
                label={t("drive")}
              />
            </div>
          </div>
          {/* Volume Range */}
          <div className={styles.rangeRowElement}>
            <RangeInput
              placeholders={[t("volumeLiters"), t("upTo")]}
              fromValue={filterData.data.volumeFrom}
              toValue={filterData.data.volumeTo}
              onFromChange={(value) =>
                handlers.handleRangeChange("volumeFrom", value)
              }
              onToChange={(value) =>
                handlers.handleRangeChange("volumeTo", value)
              }
            />
          </div>
          {/* Year Range */}
          <div className={styles.rangeRowElement}>
            <RangeInput
              placeholders={[t("yearFrom"), t("upTo")]}
              fromValue={filterData.data.yearFrom}
              toValue={filterData.data.yearTo}
              onFromChange={(value) =>
                handlers.handleRangeChange("yearFrom", value)
              }
              onToChange={(value) =>
                handlers.handleRangeChange("yearTo", value)
              }
            />
          </div>
          {/* Mileage Range */}
          <div className={styles.rangeRowElement}>
            <RangeInput
              placeholders={[t("mileageKmFrom"), t("upTo")]}
              fromValue={filterData.data.mileageFrom}
              toValue={filterData.data.mileageTo}
              onFromChange={(value) =>
                handlers.handleRangeChange("mileageFrom", value)
              }
              onToChange={(value) =>
                handlers.handleRangeChange("mileageTo", value)
              }
            />
          </div>
          <div className={styles.rangeRowElement}>
            <RangeInput
              placeholders={[t("power"), t("upTo")]}
              fromValue={filterData.data.powerFrom}
              toValue={filterData.data.powerTo}
              onFromChange={(value) =>
                handlers.handleRangeChange("powerFrom", value)
              }
              onToChange={(value) =>
                handlers.handleRangeChange("powerTo", value)
              }
            />
          </div>
        </div>

        <div style={{ margin: "12px 0", borderTop: "2px dotted #e6e6e6" }} />

        <div className={styles.mainFilterContainer}>
          {/* Regions */}
          <div className={styles.rangeRowElement}>
            <MultiSelect
              values={getCountryNames(addresses)}
              selected={filterData.data.regions}
              onSelected={handlers.handleRegionsChange}
              label={t("regions")}
            />
          </div>

          {/* Radius and Last Added */}
          <div className={styles.row}>
            <div className={styles.rowElement}>
              <Selector
                values={radius}
                value={filterData.data.radius?.toString() || ""}
                onValue={(value) =>
                  handlers.handleRadiusChange(parseInt(value))
                }
                label={t("radius")}
              />
            </div>
            <div className={styles.rowElement}>
              <Selector
                values={Object.values(lastAdded).map((item) => item.toString())}
                value={filterData.data.lastAdd?.toString() || ""}
                onValue={(value) =>
                  handlers.handleLastAddChange(parseInt(value))
                }
                label={t("lastAdded")}
              />
            </div>
          </div>

          {/* Price Range */}
          <div className={styles.rangeRowElement}>
            <RangeInput
              placeholders={[t("priceFrom"), t("upTo")]}
              fromValue={filterData.data.from_ad}
              toValue={filterData.data.to_ad}
              onFromChange={(value) =>
                handlers.handleRangeChange("from_ad", value)
              }
              onToChange={(value) => handlers.handleRangeChange("to_ad", value)}
            />
          </div>

          {/* Cities */}
          <div className={styles.rangeRowElement}>
            <MultiSelect
              values={getCitiesBySelectedCountries(
                addresses,
                filterData.data.regions
              )}
              selected={filterData.data.cities || []}
              onSelected={handlers.handleCitiesChange}
              label={t("citiesZip")}
            />
          </div>
          {/* Seller Type and Owners */}
          <div className={styles.row}>
            <div className={styles.rowElement}>
              <Selector
                values={Object.values(sellers)}
                value={filterData.data.typeOfSeller}
                onValue={handlers.handleSellerTypeChange}
                label={t("sellerType")}
              />
            </div>
            <div className={styles.rowElement}>
              <Selector
                values={Object.keys(users)}
                value={filterData.data.amountOfOwners?.toString() || ""}
                onValue={(value) =>
                  handlers.handleOwnersChange(parseInt(value))
                }
                label={t("usersQuantity")}
              />
            </div>
          </div>
        </div>

        {/* Action Buttons */}
        <div className={styles.row}>
          <div className={styles.bottomRow}>
            <button onClick={handlers.resetFilters} className={styles.button}>
              {t("reset")}
            </button>
          </div>

          <div className={styles.bottomRow} style={{ position: "relative" }}>
            <button
              onClick={() => setIsSavedFiltersOpen(!isSavedFiltersOpen)}
              className={styles.button}
            >
              {t("saveFilters")} <OrangeArrowDown />
            </button>

            {isSavedFiltersOpen && (
              <div className={styles.saveDialog} ref={saveFilterRef}>
                {/* Save new filter section */}
                <div className={styles.saveNewFilter}>
                  <input
                    type="text"
                    value={newFilterName}
                    onChange={(e) => setNewFilterName(e.target.value)}
                    placeholder={t("enterFilterName")}
                    className={styles.filterNameInput}
                  />
                  <button
                    onClick={handleSaveFilter}
                    className={styles.saveButton}
                    disabled={!newFilterName.trim()}
                  >
                    {t("save")}
                  </button>
                  {savedFilters.length > 0 && (
                    <div className={styles.savedFiltersDropdown}>
                      {savedFilters.map((filter, index) => (
                        <div key={index} className={styles.savedFilterItem}>
                          <span
                            onClick={async () => {
                              setIsLoading(true);
                              try {
                                handlers.loadSavedFilter(filter, setFilters);
                              } finally {
                                setIsLoading(false);
                                setIsSavedFiltersOpen(false);
                              }
                            }}
                            className={`${styles.savedFilterName} ${
                              isLoading ? styles.loading : ""
                            }`}
                          >
                            {filter.name}
                          </span>
                          <button
                            onClick={() => {
                              handlers.deleteSavedFilter(filter.name);
                              setIsSavedFiltersOpen(false);
                            }}
                            className={styles.deleteFilterButton}
                          >
                            ×
                          </button>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className={styles.bottomRow}>
            <Button onClick={handleSubmit} disabled={isButtonDisabled()}>
              {isMarket ? (
                t("show")
              ) : isCountLoading ? (
                t("counting")
              ) : !hasFilterChanged ? (
                <>
                  {t("show")} +1000000 {t("cars")}
                </>
              ) : carsCount !== null && carsCount > 0 ? (
                <>
                  {t("show")} {carsCount} {t("cars")}
                </>
              ) : (
                t("noCars")
              )}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
