import React, { useState, useEffect } from "react";
import styles from "./RangeInput.module.css";

interface RangeInputProps {
  placeholders: string[];
  label?: string;
  fromValue?: number | string;
  toValue?: number | string;
  onFromChange?: (value: number | undefined) => void;
  onToChange?: (value: number | undefined) => void;
}

export function RangeInput({
  placeholders,
  label,
  fromValue: externalFromValue = "",
  toValue: externalToValue = "",
  onFromChange = () => {},
  onToChange = () => {},
}: RangeInputProps) {
  const [fromValue, setFromValue] = useState<number | string | undefined>(
    externalFromValue
  );
  const [toValue, setToValue] = useState<number | string | undefined>(
    externalToValue
  );
  const [isFocused, setIsFocused] = useState({ left: false, right: false });

  // Синхронизация с внешними значениями
  useEffect(() => {
    if (externalFromValue !== fromValue) {
      setFromValue(externalFromValue);
    }
  }, [externalFromValue]);

  useEffect(() => {
    if (externalToValue !== toValue) {
      setToValue(externalToValue);
    }
  }, [externalToValue]);

  const handleFromChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    if (inputValue === "0") {
      setFromValue("");
      onFromChange(undefined);
      return;
    }

    if (inputValue === "") {
      setFromValue("");
      onFromChange(undefined);
      return;
    }

    const value = Number(inputValue);
    if (isNaN(value)) {
      return;
    }

    if (toValue === "" || toValue === undefined || value <= Number(toValue)) {
      setFromValue(value);
      onFromChange(value);
    }
  };

  const handleToChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    if (inputValue === "0") {
      setFromValue("");
      onFromChange(undefined);
      return;
    }

    if (inputValue === "") {
      setToValue("");
      onToChange(undefined);
      return;
    }

    const value = Number(inputValue);
    if (isNaN(value)) {
      return;
    }

    if (
      fromValue === "" ||
      fromValue === undefined ||
      value >= Number(fromValue)
    ) {
      setToValue(value);
      onToChange(value);
    }
  };
  return (
    <div className={styles.wrapper}>
      {label && <div className={styles.label}>{label}</div>}
      <div
        className={`${styles.container} ${
          isFocused.left || isFocused.right ? styles.focused : ""
        }`}
      >
        <input
          type="number"
          onChange={handleFromChange}
          onFocus={() => setIsFocused((prev) => ({ ...prev, left: true }))}
          onBlur={() => setIsFocused((prev) => ({ ...prev, left: false }))}
          className={styles.inputLeft}
          placeholder={placeholders[0]}
          value={fromValue === 0 ? "" : fromValue}
        />
        <input
          type="number"
          onChange={handleToChange}
          onFocus={() => setIsFocused((prev) => ({ ...prev, right: true }))}
          onBlur={() => setIsFocused((prev) => ({ ...prev, right: false }))}
          className={styles.inputRight}
          placeholder={placeholders[1]}
          value={toValue === 0 ? "" : toValue}
        />
      </div>
    </div>
  );
}
