import { ChangeUserLoginInfoForm } from "features/ChangeUserLoginInfoForm";
import styles from "./UserAccountPage.module.css";

export function UserAccountPage() {
  return (
    <>
      <div className={styles.sizedbox} />
      <div className={styles.layout}>
        <div className={styles.container}>
          <div className={styles.content}>
            <ChangeUserLoginInfoForm />
          </div>
        </div>
      </div>
    </>
  );
}
