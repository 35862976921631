import { Link, Outlet, useNavigate } from "react-router-dom";
import styles from "./Navbar.module.css";
import { ReactComponent as UserIcon } from "shared/assets/profile.svg";
import React, { useEffect, useRef, useState } from "react";
import { getFavouriteData } from "../lib/request";
import { useFavoriteContext } from "contexts/FavoriteContext";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "./LangSwitcher";
import { useAuthStore } from "store/authStore";

const Image = React.memo(function Image({
  src,
  width,
  onClick,
}: {
  src: string;
  width: string;
  onClick: () => void;
}) {
  return (
    <div className={styles.menu_logo} onClick={onClick}>
      <img src={src} width={width} alt="Logo" />
    </div>
  );
});

const BurgerMenu = ({
  isOpen,
  onClick,
}: {
  isOpen: boolean;
  onClick: () => void;
}) => (
  <button
    className={`${styles.burgerMenu} ${isOpen ? styles.open : ""}`}
    onClick={onClick}
  >
    <span></span>
    <span></span>
    <span></span>
  </button>
);

const CACHE_EXPIRATION_TIME = 60 * 60 * 1000;

export function Navbar() {
  const { t } = useTranslation();
  const accessToken = localStorage.getItem("accessToken");
  const navigate = useNavigate();
  const { setFavoriteList, favoriteList } = useFavoriteContext();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(true);

  const { isAuthenticated, setIsAuthenticated } = useAuthStore();

  const [favoriteCount, setFavoriteCount] = useState(0);

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const isCacheValid = (cacheTimestamp: number): boolean => {
    const now = Date.now();
    return now - cacheTimestamp < CACHE_EXPIRATION_TIME;
  };

  useEffect(() => {
    async function getData() {
      if (accessToken) {
        setLoading(true);

        // Проверяем кэш
        const cachedData = localStorage.getItem("favouriteData");
        const cacheTimestamp = Number(
          localStorage.getItem("favouriteDataTimestamp")
        );

        if (cachedData && cacheTimestamp && isCacheValid(cacheTimestamp)) {
          const parsedData = JSON.parse(cachedData);
          setFavoriteList(parsedData || []);
          setLoading(false);
          return;
        }

        // Если кэш невалиден или отсутствует, делаем запрос
        const favouriteData = await getFavouriteData();
        setFavoriteList(favouriteData || []);

        // Сохраняем новые данные в кэш
        localStorage.setItem("favouriteData", JSON.stringify(favouriteData));
        localStorage.setItem("favouriteDataTimestamp", Date.now().toString());
      } else {
        setFavoriteList([]);
      }
      setLoading(false);
    }
    getData();
  }, [accessToken, setFavoriteList]);
  useEffect(() => {
    if (localStorage.getItem("accessToken")) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, []);

  useEffect(() => {
    if (favoriteList.length !== favoriteCount) {
      setFavoriteCount(favoriteList.length);
    }
  }, [favoriteList, favoriteCount]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        menuRef.current &&
        event.target instanceof Node &&
        !menuRef.current.contains(event.target)
      ) {
        setIsMenuOpen(false);
        setIsSelected(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleLogoClick = () => {
    navigate("/");
  };

  const toggleMenu = () => {
    setIsMenuOpen((prevState) => !prevState);
    setIsSelected((prevState) => !prevState);
  };

  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    setIsAuthenticated(false);
    navigate("/");
  };

  return (
    <div>
      <div className={styles.container}>
        <Image width="90px" src="caraii.jpg" onClick={handleLogoClick} />

        {/* Desktop Menu */}
        <div className={styles.desktopMenu}>
          {/* Существующее меню */}
          <div className={styles.menu}>
            <Link
              className={`${styles.menu_point} ${
                window.location.pathname === "/" ? styles.active : ""
              }`}
              to="/"
            >
              {t("home")}
            </Link>
            <Link
              className={`${styles.menu_point} ${
                window.location.pathname === "/search" ? styles.active : ""
              }`}
              to="/search"
            >
              {t("search")}
            </Link>
            <Link
              className={`${styles.menu_point} ${
                window.location.pathname === "/favourites" ? styles.active : ""
              }`}
              to="/favourites"
            >
              {t("favourites")}
              {isAuthenticated ? (
                loading ? (
                  <span className={styles.favouriteCount}>{t("loading")}</span>
                ) : (
                  <span className={styles.favouriteCount}>{favoriteCount}</span>
                )
              ) : null}
            </Link>
            <Link className={styles.menu_point} to="/market">
              {t("market")}
            </Link>
            <Link
              className={`${styles.menu_point} ${
                window.location.pathname === "/api" ? styles.active : ""
              } ${styles.menu_point_market}`}
              to="/api"
            >
              {t("api")}
            </Link>
          </div>
        </div>

        {/* Mobile Menu Button */}
        <BurgerMenu isOpen={isMobileMenuOpen} onClick={toggleMobileMenu} />

        {/* User Section */}
        <div className={styles.user}>
          <LanguageSwitcher />
          {isAuthenticated ? (
            <div
              onClick={toggleMenu}
              className={`${styles.userMenuTrigger} ${
                isSelected ? styles.selected : ""
              }`}
            >
              <UserIcon />
              <p className={styles.user_name}>{t("userName")}</p>
              {isMenuOpen && (
                <div className={styles.dropdownMenu} ref={menuRef}>
                  <Link to="/profile" className={styles.dropdownItem}>
                    {t("profile")}
                  </Link>
                  <Link to="/subscription" className={styles.dropdownItem}>
                    {t("subscription")}
                  </Link>
                  <button
                    onClick={handleLogout}
                    className={`${styles.dropdownItem} ${styles.exitButton}`}
                  >
                    {t("logout")}
                  </button>
                </div>
              )}
            </div>
          ) : (
            <Link to="/login" className={styles.login_button}>
              {t("login")}
            </Link>
          )}
        </div>
      </div>

      {/* Mobile Menu Panel */}
      <div
        className={`${styles.mobileMenu} ${
          isMobileMenuOpen ? styles.open : ""
        }`}
      >
        <div className={styles.mobileMenuContent}>
          <button className={styles.closeButton} onClick={toggleMobileMenu}>
            ×
          </button>
          <Link to="/" onClick={toggleMobileMenu}>
            {t("home")}
          </Link>
          <Link to="/search" onClick={toggleMobileMenu}>
            {t("search")}
          </Link>
          <Link to="/favourites" onClick={toggleMobileMenu}>
            {t("favourites")}
            <span className={styles.mobileFavouriteCount}>{favoriteCount}</span>
          </Link>
          <Link to="/market" onClick={toggleMobileMenu}>
            {t("market")}
          </Link>
          <LanguageSwitcher />
          {isAuthenticated ? (
            <>
              <Link
                to="/profile"
                onClick={toggleMobileMenu}
                className={styles.mobileUserInfo}
              >
                <UserIcon />
                <span>{t("userName")}</span>
              </Link>
              <button
                className={styles.mobileLogoutButton}
                onClick={() => {
                  handleLogout();
                  toggleMobileMenu();
                }}
              >
                {t("logout")}
              </button>
            </>
          ) : (
            <Link
              to="/login"
              className={styles.mobileLoginButton}
              onClick={toggleMobileMenu}
            >
              {t("login")}
            </Link>
          )}
        </div>
      </div>
      <Outlet />
    </div>
  );
}
