import React, { createContext, useState, useEffect, ReactNode } from "react";
import { getMainPageData } from "../widgets/CarShowcase/lib/request";
import { MainPageResponse, MainPageContextProps } from "../common/interfaces";
import { useTranslation } from "react-i18next";

const defaultContext: MainPageContextProps = {
  mainPageData: null,
  loading: true,
  error: null,
};

// Создаем контекст
export const MainPageContext =
  createContext<MainPageContextProps>(defaultContext);

// Типизируем пропсы провайдера
interface MainPageProviderProps {
  children: ReactNode;
}

const CACHE_EXPIRATION_TIME = 60 * 60 * 1000; // 1 час в миллисекундах

export const MainPageProvider: React.FC<MainPageProviderProps> = ({
  children,
}) => {
  const [mainPageData, setMainPageData] = useState<MainPageResponse | null>(
    null
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const { t } = useTranslation();

  const isCacheValid = (cacheTimestamp: number): boolean => {
    const now = Date.now();
    return now - cacheTimestamp < CACHE_EXPIRATION_TIME;
  };

  // Фоновый запрос марок машин
  const fetchCarsMarksListInBackground = async (): Promise<void> => {
    try {
      const cachedData = localStorage.getItem("carsMarksList");
      const cacheTimestamp = Number(localStorage.getItem("carsMarksTimestamp"));

      if (cachedData && cacheTimestamp && isCacheValid(cacheTimestamp)) {
        return; // Используем кэшированные данные
      }
    } catch (error: any) {
      console.error("Ошибка при загрузке марок машин:", error);
    }
  };

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      try {
        const cachedData = localStorage.getItem("mainPageData");
        const cacheTimestamp = Number(
          localStorage.getItem("mainPageDataTimestamp")
        );

        if (cachedData && cacheTimestamp && isCacheValid(cacheTimestamp)) {
          setMainPageData(JSON.parse(cachedData));
          setLoading(false);
          return;
        }

        const data = await getMainPageData();
        if (data) {
          setMainPageData(data);
          localStorage.setItem("mainPageData", JSON.stringify(data));
          localStorage.setItem("mainPageDataTimestamp", Date.now().toString());
        } else {
          setError(t("mainPageDataNoGet"));
        }
      } catch (error: any) {
        setError(t("errorOnDataLoading"));
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    fetchCarsMarksListInBackground();
  }, []);

  return (
    <MainPageContext.Provider value={{ mainPageData, loading, error }}>
      {children}
    </MainPageContext.Provider>
  );
};
