import { PATH_API } from "../../config/config";

const BaseapiUrl = `${PATH_API}/v1`;

// Интерфейс для конфигурации запроса
interface RequestConfig {
  method: "GET" | "POST" | "PATCH" | "DELETE" | "PUT";
  path: string;
  data?: Record<string, any>; // Типизируем `data` как объект с произвольными ключами и значениями
}

// Общая функция для отправки запросов
async function sendRequest<T>({
  method,
  path,
  data,
}: RequestConfig): Promise<T> {
  // Формируем URL с учетом наличия параметров для метода GET
  const url =
    method === "GET" && data
      ? `${BaseapiUrl}/${path}?${new URLSearchParams(data).toString()}`
      : `${BaseapiUrl}/${path}`;

  const response = await fetch(url, {
    method,
    headers: {
      "Content-Type": "application/json;charset=utf-8",
    },
    ...(method !== "GET" ? { body: JSON.stringify(data) } : {}), // Добавляем тело запроса только для методов, отличных от GET
  });

  // Проверка аутентификации
  checkAuth(response);

  // Попытка извлечь JSON-ответ, если успешен
  const result: T = await response.json();
  return result;
}

// Функция для отправки GET-запросов
export async function sendGetRequest<T>(
  path: string,
  params?: Record<string, any>
): Promise<T> {
  return sendRequest<T>({ method: "GET", path, data: params });
}

// Функция для отправки POST-запросов
export async function sendPostRequest<T>(
  path: string,
  data: Record<string, any>
): Promise<T> {
  return sendRequest<T>({ method: "POST", path, data });
}

// Функция для отправки PATCH-запросов
export async function sendPatchRequest<T>(
  path: string,
  data: Record<string, any>
): Promise<T> {
  return sendRequest<T>({ method: "PATCH", path, data });
}

export async function sendPutRequest<T>(
  path: string,
  data: Record<string, any>
): Promise<T> {
  return sendRequest<T>({ method: "PUT", path, data });
}

// Функция для отправки DELETE-запросов
export async function sendDeleteRequest<T>(
  path: string,
  data: Record<string, any>
): Promise<T> {
  return sendRequest<T>({ method: "DELETE", path, data });
}

// Функция проверки аутентификации
export function checkAuth(res: Response): void {
  // Перенаправляем пользователя на страницу входа, если код статуса указывает на проблему с аутентификацией
  if (res.status === 403 || res.status === 401) {
    window.location.replace("https://caraii.com/login");
  }
}
