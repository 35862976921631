const ArrowDownIcon = () => {
  return (
    <svg
      style={{ paddingTop: 4 }}
      width="14px"
      height="12px"
      viewBox="0 0 10 6"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="Page-1"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <g
          id="01-01-Home"
          transform="translate(-1088, -37)"
          stroke="#A5A6A7"
          stroke-width="1.2"
        >
          <g id="Group-12" transform="translate(1042, 30)">
            <polyline
              id="Path-26"
              transform="translate(51, 9.75) rotate(-270) translate(-51, -9.75)"
              points="49.25 6.25 52.75 9.75 49.25 13.25"
            ></polyline>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ArrowDownIcon;
