import { useState, useEffect } from "react";
import { CarFilters } from "features/СarFilters";
import { CarsList } from "widgets/CarsList/ui/CarsList";
import styles from "./OffersPage.module.css";
import { Cars } from "common/interfaces";
import { getCars } from "features/СarFilters/lib/request";
import { FilterData, SortField } from "features/СarFilters/model/interfaces";
import { useTranslation } from "react-i18next";
import { ArrowLeftIcon, ArrowRightIcon } from "lucide-react";

export function OffersPage() {
  const { t } = useTranslation();
  const mainPageData = localStorage.getItem("mainPageData");
  const cahedCars = mainPageData ? JSON.parse(mainPageData).data.cars : [];
  const [cars, setCars] = useState<Cars[]>(cahedCars);
  const [loading, setLoading] = useState(false);
  const [sortLoading, setSortLoading] = useState(false);
  const [filters, setFilters] = useState<FilterData | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCars, setTotalCars] = useState<number | null>(null);
  const itemsPerPage = 10; // Количество элементов на странице
  const totalPages = totalCars ? Math.ceil(totalCars / itemsPerPage) : 0;

  const [currentSort, setCurrentSort] = useState<{
    field: SortField["field"];
    order: SortField["order"];
  }>({
    field: "price",
    order: "asc",
  });

  const handleSort = async (
    field: SortField["field"],
    order: SortField["order"]
  ) => {
    if (!filters) return;

    try {
      setSortLoading(true);
      setCurrentSort({ field, order });

      const updatedFilters = {
        ...filters,
        sort_metrics: {
          sort_fields: [{ field, order }],
        },
      };

      const carsList = await getCars(updatedFilters);
      setCars(carsList);
      setFilters(updatedFilters);
    } catch (error) {
      console.error("Error sorting cars:", error);
      alert("Something went wrong while sorting the cars. Please try again.");
    } finally {
      setSortLoading(false);
    }
  };

  const handleCarsCountChange = (count: number | null) => {
    setTotalCars(count);
    // Сбрасываем страницу на первую при изменении общего количества
    if (currentPage !== 1) {
      setCurrentPage(1);
    }
  };

  const handleCarsSearch = async (filterData: FilterData | null) => {
    if (!filterData) return;
    try {
      setLoading(true);
      const paginatedFilters = {
        ...filterData,
        data: {
          ...filterData.data,
          from_ad: ((currentPage - 1) * itemsPerPage).toString(),
          to_ad: (currentPage * itemsPerPage).toString(),
        },
      };
      const carsList = await getCars(paginatedFilters);
      setCars(carsList);
    } catch (error) {
      console.error("Error fetching cars:", error);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = async (newPage: number) => {
    setCurrentPage(newPage);
    if (filters) {
      const updatedFilters = {
        ...filters,
        data: {
          ...filters.data,
          from_ad: ((newPage - 1) * itemsPerPage).toString(),
          to_ad: (newPage * itemsPerPage).toString(),
        },
      };
      handleCarsSearch(updatedFilters);
    }
  };
  useEffect(() => {
    if (!sortLoading && filters) {
      handleCarsSearch(filters);
    }
  }, [filters, currentPage]);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <CarFilters
          setFilters={setFilters}
          onCarsCountChange={handleCarsCountChange}
        />
        <CarsList
          cars={cars}
          onSort={handleSort}
          currentSort={currentSort}
          loading={loading || sortLoading}
        />
        {totalCars !== null && totalCars > 0 && (
          <div className={styles.pagination}>
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1 || loading}
              className={styles.paginationButton}
            >
              <ArrowLeftIcon />
            </button>
            <span className={styles.pageInfo}>
              {currentPage} / {totalPages}
            </span>
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage >= totalPages || loading}
              className={styles.paginationButton}
            >
              <ArrowRightIcon />
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
