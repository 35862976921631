import styles from "./CarsListHeader.module.css";
import { useTranslation } from "react-i18next";
import { SortField } from "features/СarFilters/model/interfaces";

interface CarsListHeaderProps {
  onSort: (field: SortField["field"], order: SortField["order"]) => void;
  currentSort: { field: SortField["field"]; order: SortField["order"] };
}

export function CarsListHeader({ onSort, currentSort }: CarsListHeaderProps) {
  const { t } = useTranslation();

  const handleSort = (field: SortField["field"]) => {
    const newOrder =
      currentSort.field === field && currentSort.order === "asc"
        ? "desc"
        : "asc";
    onSort(field, newOrder);
  };

  const getSortIcon = (field: SortField["field"]) => {
    if (currentSort.field !== field) return "↕️";
    return currentSort.order === "asc" ? "↑" : "↓";
  };

  return (
    <div className={styles.container}>
      <div className={`${styles.column} ${styles.toHide}`}>
        <p>{t("photo")}</p>
      </div>

      <div className={`${styles.column} ${styles.toHide}`}>
        <p>{t("date")}</p>
      </div>

      <div className={styles.column}>
        <p>{t("car")}</p>
      </div>

      <div className={`${styles.column} ${styles.toHide}`}>
        <p onClick={() => handleSort("age")} style={{ cursor: "pointer" }}>
          {t("year")} {getSortIcon("age")}
        </p>
      </div>

      <div className={styles.column}>
        <p onClick={() => handleSort("price")} style={{ cursor: "pointer" }}>
          {t("price")} {getSortIcon("price")}
        </p>
      </div>

      <div className={styles.column}>
        <p onClick={() => handleSort("km_age")} style={{ cursor: "pointer" }}>
          {t("mileage")} {getSortIcon("km_age")}
        </p>
      </div>

      <div className={`${styles.column} ${styles.toHide}`}>
        <p
          onClick={() => handleSort("displacement")}
          style={{ cursor: "pointer" }}
        >
          {t("transmissionVolume")} {getSortIcon("displacement")}
        </p>
      </div>

      <div className={`${styles.column} ${styles.toHide}`}>
        <p>{t("city")}</p>
      </div>

      <div className={styles.column}>
        <p>{t("bodyColor")}</p>
      </div>
    </div>
  );
}
