import React, { useEffect, useState } from "react";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import europeData from "./europe.geojson";
import styles from "./Map.module.css";
import { useTranslation } from "react-i18next";

interface MapChartProps {
  onChangeCountries: (countries: string[]) => void;
  selected: string[];
  adresses: any;
}

interface GeoProperties {
  name: string;
}

interface GeographyShape {
  rsmKey: string;
  properties: GeoProperties;
}

const MapChart: React.FC<MapChartProps> = ({
  onChangeCountries,
  selected,
  adresses,
}) => {
  const { t } = useTranslation();
  const [selectedCountries, setSelectedCountries] =
    useState<string[]>(selected); // Для хранения кодов выбранных стран
  const [selectedCountriesNames, setSelectedCountriesNames] =
    useState<string[]>(selected);
  const [hoveredCountry, setHoveredCountry] = useState<string | null>(null); // Для хранения названия страны при наведении
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 }); // Для хранения позиции мыши

  const countriesAndCodes = {
    Andorra: "AD",
    "United Arab Emirates": "AE",
    Albania: "AL",
    BosniaandHerzegovina: "BA",
    Bulgaria: "BG",
    CzechRepublic: "CZ",
    Germany: "DE",
    Estonia: "EE",
    Spain: "ES",
    Finland: "FI",
    France: "FR",
    Greece: "GR",
    Croatia: "HR",
    Hungary: "HU",
    Italy: "IT",
    SouthKorea: "KR",
    Lebanon: "LB",
    Lithuania: "LT",
    Latvia: "LV",
    Monaco: "MC",
    Montenegro: "ME",
    NorthMacedonia: "MK",
    Serbia: "RS",
    Sweden: "SE",
    Slovenia: "SI",
    Slovakia: "SK",
    Turkey: "TR",
    Ukraine: "UA",
    UnitedStates: "US",
  };

  // Обновление выбранных стран при изменении пропсов `selected`
  useEffect(() => {
    setSelectedCountriesNames(selected);
    setSelectedCountries(selected);
  }, [selected]);

  // Обработчик для наведения на страну с явной типизацией `event`
  const handleMouseEnter = (
    name: string,
    event: React.MouseEvent<SVGPathElement, MouseEvent>
  ) => {
    setHoveredCountry(name);
    setMousePosition({ x: event.clientX, y: event.clientY });
  };

  const handleMouseLeave = () => {
    setHoveredCountry(null);
  };

  // Обработчик клика по стране
  const handleCountryClick = (isoCode: string, name: string) => {
    setSelectedCountriesNames((prevSelected) => {
      if (prevSelected.includes(name)) {
        const updatedCountries = prevSelected.filter((code) => code !== name);
        onChangeCountries(updatedCountries);
        return updatedCountries;
      } else {
        const updatedCountries = [...prevSelected, name];
        onChangeCountries(updatedCountries);
        return updatedCountries;
      }
    });
  };

  return (
    <>
      <ComposableMap
        projection="geoAzimuthalEqualArea" // Установка проекции для Европы
        projectionConfig={{
          rotate: [-10, -52, 0],
          scale: 900,
        }}
        className={styles.container}
        // Подгоняем размеры карты под контейнер
      >
        <Geographies geography={europeData}>
          {({ geographies }: { geographies: GeographyShape[] }) => (
            <>
              {geographies.map((geo: GeographyShape) => {
                const countryName = geo.properties.name;
                if (Object.keys(countriesAndCodes).indexOf(countryName) == -1) {
                  return;
                }
                const isSelected = selectedCountries.includes(countryName);

                return (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    stroke="#FFFFFF"
                    style={{
                      default: {
                        outline: "none",
                        cursor: "pointer",
                        strokeWidth: 0.5,
                        fill: isSelected ? "rgb(255,110,4)" : "#D6D6DA",
                      },
                      hover: {
                        fill: "rgb(131,131,131)",
                        outline: "none",
                      },
                      pressed: {
                        fill: isSelected ? "rgb(255,110,4)" : "#D6D6DA",
                        outline: "none",
                      },
                    }}
                    onClick={() => handleCountryClick(geo.rsmKey, countryName)}
                    onMouseEnter={(
                      event: React.MouseEvent<SVGPathElement, MouseEvent>
                    ) => handleMouseEnter(countryName, event)}
                    onMouseLeave={handleMouseLeave}
                  />
                );
              })}
            </>
          )}
        </Geographies>
      </ComposableMap>
      {hoveredCountry && (
        <div
          style={{
            position: "absolute",
            top: mousePosition.y + 400,
            left: mousePosition.x,
            backgroundColor: "black",
            padding: "5px",
            border: "1px solid black",
            pointerEvents: "none",
            zIndex: 10,
            opacity: 1,
            color: "rgba(255, 255, 255, 1)",
            fontSize: "12px",
            fontWeight: 400,
            borderRadius: "4px",
          }}
        >
          {t(hoveredCountry)}
        </div>
      )}
    </>
  );
};

export default MapChart;
