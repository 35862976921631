import React, { useState } from "react";
import styles from "./AutoNameSelector.module.css";
import { ListOfTypes } from "shared/ui/ListOfTypes/ui/ListOfTypes";
import { ListOfBrands } from "shared/ui/ListOfBrands";

export function AutoNameSelector() {
  const types = ["all", "new", "used"];
  const [selectedType, setSelectedType] = useState("all");
  return (
    <div className={styles.container}>
      <div className={styles.headerRow}>
        <div className={styles.rowElement}>
          <ListOfTypes
            values={types}
            selected={selectedType}
            onSelect={setSelectedType}
          />
        </div>
      </div>
      <ListOfBrands selectedType={selectedType} />
    </div>
  );
}
