import styles from "./Checkbox.module.css";
import React from "react";

interface CheckboxProps {
  id: string;
  label: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>; // Добавляем htmlFor в пропсы
  checked: boolean;
}

export const Checkbox: React.FC<CheckboxProps> = ({
  id,
  label,
  onChange,
  checked = false,
}) => {
  return (
    <div className={styles.container}>
      <input
        onChange={onChange}
        className={styles.checkbox}
        checked={checked}
        type="checkbox"
        id={id}
      />
      <label className={styles.label} htmlFor={id}>
        {label}
      </label>{" "}
      {/* Используем htmlFor */}
    </div>
  );
};
