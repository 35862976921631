import styles from "./SmallCardSkeleton.module.css";

export function SmallCardSkeleton() {
  return (
    <div className={styles.carCard}>
      <div className={`${styles.skeleton} ${styles.imageWrapper}`} />
      <div className={styles.carInfo}>
        <div className={`${styles.skeleton} ${styles.price}`} />
        <div className={`${styles.skeleton} ${styles.model}`} />
        <div className={`${styles.skeleton} ${styles.details}`} />
      </div>
    </div>
  );
}
