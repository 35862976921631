import { useTranslation } from "react-i18next";
import styles from "./ApiPage.module.css";

export const ApiPage = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.layout}>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.header_info}>
            <h1>{t("api")}</h1>
          </div>
          <div className={styles.header_info}>{t("apiNotReady")}</div>
        </div>
      </div>
    </div>
  );
};
