import React, { PropsWithChildren } from "react";
import styles from "./Button.module.css";

interface ButtonProps extends PropsWithChildren {
  onClick: () => void;
  width?: string;
  fontSize?: string;
  disabled?: boolean; // Добавляем тип для disabled
  className?: string;
}

export const Button: React.FC<ButtonProps> = ({
  children,
  onClick,
  width = "100%",
  fontSize = "unset",
  disabled = false,
  className,
}) => {
  return (
    <button
      onClick={onClick}
      className={`${styles.button} ${
        disabled ? styles.buttonDisabled : ""
      } ${className}`}
      style={{ width: width, fontSize: fontSize }}
      disabled={disabled} // Отключаем кнопку
    >
      {children}
    </button>
  );
};
