export function parseDateTime(dateTimeString: string) {
  const months = [
    "январь",
    "февраль",
    "март",
    "апрель",
    "май",
    "июнь",
    "июль",
    "август",
    "сентябрь",
    "октябрь",
    "ноябрь",
    "декабрь",
  ];

  const date = new Date(dateTimeString);

  const year = date.getUTCFullYear();
  const month = months[date.getUTCMonth()];
  const day = String(date.getUTCDate()).padStart(2, "0");
  const time = date.toISOString().slice(11, 16); // Часы и минуты

  return {
    year: year,
    month: month,
    day: day,
    time: time,
  };
}
