import React, { useState, useRef, useEffect } from "react";
import styles from "./MultiSelect.module.css";
import { ArrowDown } from "../../Icons/ArrowDown";
import { useTranslation } from "react-i18next";

interface MultiSelectProps {
  label: string; // Строка для метки
  values: string[]; // Массив строк для вариантов выбора
  selected: string[]; // Массив строк для выбранных значений
  onSelected: (selected: string[]) => void; // Функция обратного вызова для передачи выбранных значений
  disabled?: boolean;
}

export const MultiSelect: React.FC<MultiSelectProps> = ({
  label,
  values = [],
  selected = [],
  onSelected,
  disabled = false,
}) => {
  const { t } = useTranslation();
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [selectedValues, setSelectedValues] = useState<string[]>(selected);

  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (JSON.stringify(selectedValues) !== JSON.stringify(selected)) {
      setSelectedValues(selected);
    }
  }, [selected]);

  const toggleDropdown = () => {
    if (!disabled) {
      setIsDropdownOpen((prev) => !prev);
    }
  };

  const handleOptionClick = (option: string) => {
    if (!disabled) {
      const newValues = selectedValues.includes(option)
        ? selectedValues.filter((item) => item !== option)
        : [...selectedValues, option];

      setSelectedValues(newValues);
      onSelected(newValues);
    }
  };

  const handleReset = () => {
    if (!disabled) {
      setSelectedValues([]);
      onSelected([]);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.dropdownContainer} ref={dropdownRef}>
      <div
        className={`${styles.dropdown} 
          ${isDropdownOpen ? styles.open : ""} 
          ${disabled ? styles.disabled : ""}`}
        onClick={disabled ? undefined : toggleDropdown}
        style={{ cursor: disabled ? "not-allowed" : "pointer" }}
      >
        <label
          className={`${styles.label} ${
            selectedValues.length > 0 || isDropdownOpen ? styles.floating : ""
          }`}
        >
          {label}
        </label>
        {selectedValues.length > 0 ? (
          <span className={styles.selectedText}>
            {selectedValues.map((value) => t(value)).join(", ")}
          </span>
        ) : (
          <span
            className={`${styles.placeholder} ${
              disabled ? styles.disabled : ""
            }`}
          >
            {t("select")}
          </span>
        )}
        <span className={styles.arrow}>
          <ArrowDown isActive={isDropdownOpen} />
        </span>
      </div>
      {isDropdownOpen && !disabled && (
        <div className={styles.values}>
          {values.map((option, index) => (
            <div
              key={index}
              className={`${styles.option} ${
                selectedValues.includes(option) ? styles.selected : ""
              }`}
              onClick={() => handleOptionClick(option)}
            >
              {t(option)}
            </div>
          ))}
          <div className={styles.buttons}>
            <button
              className={styles.deleteButton}
              onClick={handleReset}
              disabled={disabled}
            >
              {t("reset")}
            </button>
            <button
              className={styles.okButton}
              onClick={() => setIsDropdownOpen(false)}
              disabled={disabled}
            >
              {t("apply")}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
