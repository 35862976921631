import { FlyingLogo } from "shared/ui/FlyingLogo";
import { RegistrationSteps } from "widgets/RegistrationSteps";
import styles from "./RegistrationPage.module.css";
import React from "react";

export function RegistrationPage() {
  return (
    <div className={styles.container}>
      <FlyingLogo />
      <div className={styles.content}>
        <RegistrationSteps />
      </div>
    </div>
  );
}
