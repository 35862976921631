import { sendGetRequest } from "../../../shared/api/lib/requests";

export async function getFavouriteData() {
  const accessToken = localStorage.getItem("accessToken");

  // Убираем access_token из запроса, если accessToken отсутствует
  const params = accessToken ? { access_token: accessToken } : {};

  const res = await sendGetRequest<{
    data: {
      cars: any[]; // Replace `any` with a proper type for cars
    };
  }>("cars-system/favourite", params);
  return res.data.cars;
}
