import { sendGetRequest } from "shared/api/lib/requests";
import { create } from "zustand";
import { persist } from "zustand/middleware";

export type AddressDataType = {
  [countryCode: string]: {
    [cityOrRegion: string]: string[];
  };
};

interface AddressStore {
  addresses: AddressDataType | null;
  isLoading: boolean;
  error: string | null;
  setAddresses: (data: AddressDataType) => void;
  fetchAddresses: () => Promise<void>;
  loadFromCache: () => void;
}

const ONE_HOUR = 60 * 60 * 1000;

const useAddressStore = create<AddressStore>()(
  persist(
    (set, get) => ({
      addresses: null,
      isLoading: false,
      error: null,

      setAddresses: (data) => {
        set({ addresses: data });
        const cachedData = {
          timestamp: Date.now(),
          data,
        };
        localStorage.setItem("address_cache", JSON.stringify(cachedData));
      },

      fetchAddresses: async () => {
        set({ isLoading: true, error: null });
        try {
          const response: any = await sendGetRequest("cars-system/extra-info"); // Replace with your API endpoint
          const data: AddressDataType = response.data.adresses.data;
          set({ addresses: data });
          const cachedData = {
            timestamp: Date.now(),
            data,
          };
          localStorage.setItem("address_cache", JSON.stringify(cachedData));
        } catch (error: any) {
          set({ error: error.message || "Failed to fetch addresses" });
        } finally {
          set({ isLoading: false });
        }
      },

      loadFromCache: () => {
        const cache = localStorage.getItem("address_cache");
        if (cache) {
          const { timestamp, data } = JSON.parse(cache);
          if (Date.now() - timestamp < ONE_HOUR) {
            set({ addresses: data });
          } else {
            localStorage.removeItem("address_cache"); // Expired
          }
        }
      },
    }),
    {
      name: "address-storage",
    }
  )
);

export default useAddressStore;
