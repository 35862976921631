import React from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { RegistrationPage } from "pages/RegistartionPage";
import { UserAccountPage } from "pages/UserAccountPage/ui/UserAccountPage";
import { MainPage } from "pages/main";
import { OffersPage } from "pages/OffersPage/ui/OffersPage";
import { MarketPage } from "pages/MarketPage";
import { FavouritesPage } from "pages/FavouritesPage";
import { Navbar } from "widgets/navbar";
import { CarDetails } from "pages/CarDetails/ui/CarDetails";
import { ApiPage } from "pages/ApiPage/ui/ApiPage";
import { FavoriteContextProvider } from "contexts/FavoriteContext";

// Типизируем функциональный компонент как React.FC
const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<RegistrationPage />} />
        <Route
          path="/"
          element={
            <FavoriteContextProvider>
              <Navbar />
            </FavoriteContextProvider>
          }
        >
          <Route path="/profile" element={<UserAccountPage />} />
          <Route path="" element={<MainPage />} />
          <Route path="/search" element={<OffersPage />} />
          <Route path="/favourites" element={<FavouritesPage />} />
          <Route path="/market/:id?" element={<MarketPage />} />
          {/* <Route path='/' element={<MapChart />} /> */}
          {/* <Route path='/chat' element={<ChatPage />} /> */}
          <Route path="/cars/:id" element={<CarDetails />} />
          <Route path="/api" element={<ApiPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
