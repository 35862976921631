import {
  sendGetRequest,
  sendPostRequest,
} from "../../../shared/api/lib/requests";
import {
  AvaliableCarsResponse,
  FilterData,
  CarsCountResponse,
} from "../model/interfaces";
import {
  AllCarsResponse,
  Cars,
  CarsResponse,
} from "../../../common/interfaces";

export const getCarsNames = async (): Promise<AvaliableCarsResponse> => {
  const res: AvaliableCarsResponse =
    await sendGetRequest<AvaliableCarsResponse>("search/available-cars");
  return res;
};

export const validateFilters = (filter: FilterData): Partial<FilterData> => {
  // Фильтрация параметров с пустыми строками, нулями или неопределёнными значениями
  const cleanedFilter = Object.fromEntries(
    Object.entries(filter).filter(([key, value]) => {
      if (typeof value === "number") {
        return value !== 0 && !isNaN(value); // Убираем числовые поля, если значение 0 или NaN
      }
      if (Array.isArray(value)) {
        return value.length > 0; // Убираем массивы, если они пустые
      }
      return value !== "" && value !== undefined && value !== null; // Убираем пустые строки, undefined и null
    })
  );
  console.log("cleanedFilter", cleanedFilter);
  return cleanedFilter;
};

export const getCarsCount = async (
  filter: FilterData
): Promise<{ count: number }> => {
  const cleanedFilter = removeEmptyFields(filter);

  console.log("cleanedFilter", cleanedFilter);
  const filterData =
    cleanedFilter.data.brand_details[0].brand === ""
      ? { ...cleanedFilter.data, brand_details: undefined }
      : cleanedFilter.data;
  const res: CarsCountResponse = await sendPostRequest<CarsCountResponse>(
    `cars-system/cars-count`,
    filterData
  );

  return res.data;
};

function removeEmptyFields(obj: any): any {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      // Check if the key is 'brand_details'
      if (key === "brand_details") {
        // If 'brand_details' is an array, remove empty objects
        if (
          Array.isArray(obj[key]) &&
          obj[key].every((item: any) => Object.keys(item).length === 0)
        ) {
          delete obj[key]; // Remove the 'brand_details' field if it contains only empty objects
        }
      }
      // Remove keys if the value is empty, null, or an empty array
      else if (
        obj[key] === "" ||
        obj[key] === null ||
        (Array.isArray(obj[key]) && obj[key].length === 0)
      ) {
        delete obj[key];
      }
      // If the value is an object, recursively call removeEmptyFields
      else if (typeof obj[key] === "object" && obj[key] !== null) {
        removeEmptyFields(obj[key]);
      }
    }
  }
  return obj;
}

export const getCars = async (filter: FilterData): Promise<Cars[]> => {
  try {
    const cleanedFilter = removeEmptyFields(filter);
    console.log("cleanedFilter", cleanedFilter);
    const filterData =
      cleanedFilter.data.brand_details[0].brand === ""
        ? { ...cleanedFilter.data, brand_details: undefined }
        : cleanedFilter.data;
    const final = {
      ...cleanedFilter.sort_metrics,
      data: filterData,
    };
    const res: CarsResponse = await sendPostRequest<CarsResponse>(
      "search/search",
      final
    );
    console.log("resBaha", res.data.cars);
    return res.data.cars;
  } catch (error) {
    console.error("Error in getCars:", error);
    throw error;
  }
};

export const getMarketCars = async (filter: FilterData): Promise<Cars[]> => {
  const cleanedFilter: FilterData = removeEmptyFields(filter);
  console.log("cleanedFilter", cleanedFilter);
  const transformedData: any = {
    ...cleanedFilter.sort_metrics,
    data: {
      ...cleanedFilter.data,
      brand: cleanedFilter.data.brand_details[0].brand,
      model: cleanedFilter.data.brand_details[0].models.join(", "),
      generation: cleanedFilter.data.brand_details[0].generation.length
        ? cleanedFilter.data.brand_details[0].generation
        : [0],
      custom_input: cleanedFilter.data.brand_details[0].custom_input,
      brand_details: undefined,
    },
  };

  const final: any = {
    ...transformedData.sort_metrics,
    data: transformedData.data,
  };
  console.log("transformedData", transformedData);
  const res: CarsResponse = await sendPostRequest<CarsResponse>(
    "exchange/search",
    final
  );

  const carList: Cars[] = res.data.cars;

  return carList;
};

export const getALlCars = async (): Promise<Cars[]> => {
  // Валидация фильтров

  const res: AllCarsResponse = await sendGetRequest<AllCarsResponse>(
    "search/available-cars"
  );

  const carList: Cars[] = res.data.cars.cars;
  console.log("res carList", carList);

  return carList;
};
