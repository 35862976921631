import { CarsList } from "widgets/CarsList/ui/CarsList";
import { MapStatistics } from "widgets/MapStatistics/";
import styles from "./MarketPage.module.css";
import { useEffect, useState, useCallback } from "react";
import { europeanCountriesAndCities } from "../model/constants";
import { Cars } from "common/interfaces";
import { getMarketCars } from "features/СarFilters/lib/request";
import { FilterData, SortField } from "features/СarFilters/model/interfaces";
import { CarFilters } from "features/СarFilters";

export function MarketPage() {
  const [cars, setCars] = useState<Cars[]>([]);
  const [loading, setLoading] = useState(false);
  const [sortLoading, setSortLoading] = useState(false);
  const [filters, setFilters] = useState<FilterData | null>(null);
  const [selectedRegions, setSelectedRegions] = useState<string[]>([]);
  const [currentSort, setCurrentSort] = useState<{
    field: SortField["field"];
    order: SortField["order"];
  }>({
    field: "price",
    order: "asc",
  });

  const handleSelectRegion = useCallback((selectedValues: string[]) => {
    setSelectedRegions(selectedValues);

    // Update filters with new regions
    setFilters((prevFilters) => {
      if (!prevFilters) return null;

      return {
        ...prevFilters,
        data: {
          ...prevFilters.data,
          regions: selectedValues,
          // Update cities based on selected regions
          cities: selectedValues.flatMap(
            (region) => europeanCountriesAndCities[region] || []
          ),
        },
      };
    });
  }, []);

  const handleCarsSearch = async (filterData: FilterData | null) => {
    if (!filterData) return;

    try {
      setLoading(true);
      const carsList = await getMarketCars(filterData);
      if (carsList) {
        setCars(carsList);
      } else {
        setCars([]);
      }
    } catch (error) {
      console.error("Error fetching market cars:", error);
      setCars([]);
    } finally {
      setLoading(false);
    }
  };

  const handleSort = async (
    field: SortField["field"],
    order: SortField["order"]
  ) => {
    if (!filters) return;

    try {
      setSortLoading(true);
      setCurrentSort({ field, order });

      const updatedFilters = {
        ...filters,
        sort_metrics: {
          sort_fields: [{ field, order }],
        },
      };

      const carsList = await getMarketCars(updatedFilters);
      if (carsList) {
        setCars(carsList);
        setFilters(updatedFilters);
      }
    } catch (error) {
      console.error("Error sorting cars:", error);
    } finally {
      setSortLoading(false);
    }
  };

  useEffect(() => {
    // Only fetch if filters change but not from sorting
    if (!sortLoading) {
      handleCarsSearch(filters);
    }
  }, [filters]);

  return (
    <div className={styles.container}>
      <CarFilters
        setFilters={setFilters}
        isMarket={true}
        selectedRegions={selectedRegions}
      />
      <MapStatistics
        selected={selectedRegions}
        handleRegions={handleSelectRegion}
        regions={selectedRegions}
      />
      <CarsList
        cars={cars}
        onSort={handleSort}
        currentSort={currentSort}
        loading={loading || sortLoading}
      />
    </div>
  );
}
