import {
  useState,
  useCallback,
  SetStateAction,
  Dispatch,
  useEffect,
} from "react";
import { marks, models } from "../model/constants";
import { FilterData } from "../model/interfaces";

interface SavedFilter {
  name: string;
  data: FilterData;
}

export function useCarFiltersData(isMarket = false) {
  const [filterData, setFilterData] = useState<FilterData>({
    data: {
      carType: "all",
      isAccidentFree: false,
      isBelowMarket: false,
      isVat: false,
      carBody: [],
      transmission: [],
      radius: null,
      lastAdd: null,
      typeOfSeller: "",
      amountOfOwners: null,
      fuelTypes: "",
      driveTypes: "",
      mileageFrom: "",
      mileageTo: "",
      volumeFrom: "",
      volumeTo: "",
      powerFrom: "",
      powerTo: "",
      yearFrom: "",
      yearTo: "",
      regions: [],
      cities: [],
      from_ad: "",
      to_ad: "",
      brand_details: [
        { brand: "", models: [], generation: [0], custom_input: "" },
      ],
    },
    sort_metrics: {
      sort_fields: [{ field: "price", order: "asc" }],
    },
  });

  const [hasFilterChanged, setHasFilterChanged] = useState(false);

  const [savedFilters, setSavedFilters] = useState<SavedFilter[]>(() => {
    const saved = localStorage.getItem("savedCarFilters");
    return saved ? JSON.parse(saved) : [];
  });

  const [brandsList] = useState<string[]>(marks);

  const getModelsByBrand = useCallback((brand: string): string[] => {
    if (!brand) return [];
    return models[brand as keyof typeof models] || [];
  }, []);
  useEffect(() => {
    const cachedFilterData = localStorage.getItem("filterData");
    if (cachedFilterData) {
      setFilterData(JSON.parse(cachedFilterData));
    }
  }, []);
  const handlers = {
    handleTypeChange: useCallback((type: string) => {
      setFilterData((prev) => {
        const newFilterData = {
          ...prev,
          data: { ...prev.data, carType: type },
        };

        return newFilterData;
      });
      setHasFilterChanged(true);
    }, []),
    handleCheckboxChange: useCallback(
      (
        checked: boolean,
        field: keyof Pick<
          FilterData["data"],
          "isAccidentFree" | "isBelowMarket" | "isVat"
        >
      ) => {
        setFilterData((prev) => {
          const newFilterData = {
            ...prev,
            data: { ...prev.data, [field]: checked },
          };

          return newFilterData;
        });
        setHasFilterChanged(true);
      },
      []
    ),

    handleBodyTypeChange: useCallback((types: string[]) => {
      setFilterData((prev) => {
        const newFilterData = {
          ...prev,
          data: { ...prev.data, carBody: types },
        };

        return newFilterData;
      });
      setHasFilterChanged(true);
    }, []),

    handleTransmissionChange: useCallback((types: string[]) => {
      setFilterData((prev) => {
        const newFilterData = {
          ...prev,
          data: { ...prev.data, transmission: types },
        };

        return newFilterData;
      });
      setHasFilterChanged(true);
    }, []),

    handleRadiusChange: useCallback((radius: number) => {
      setFilterData((prev) => {
        const newFilterData = {
          ...prev,
          data: { ...prev.data, radius },
        };

        return newFilterData;
      });
      setHasFilterChanged(true);
    }, []),

    handleLastAddChange: useCallback((lastAdd: number) => {
      setFilterData((prev) => {
        const newFilterData = {
          ...prev,
          data: { ...prev.data, lastAdd },
        };

        return newFilterData;
      });
      setHasFilterChanged(true);
    }, []),

    handleSellerTypeChange: useCallback((type: string) => {
      setFilterData((prev) => {
        const newFilterData = {
          ...prev,
          data: { ...prev.data, typeOfSeller: type },
        };

        return newFilterData;
      });
      setHasFilterChanged(true);
    }, []),

    handleOwnersChange: useCallback((count: number) => {
      setFilterData((prev) => {
        const newFilterData = {
          ...prev,
          data: { ...prev.data, amountOfOwners: count },
        };

        return newFilterData;
      });
      setHasFilterChanged(true);
    }, []),

    handleFuelTypeChange: useCallback((type: string) => {
      setFilterData((prev) => {
        const newFilterData = {
          ...prev,
          data: { ...prev.data, fuelTypes: type },
        };

        return newFilterData;
      });
      setHasFilterChanged(true);
    }, []),

    handleDriveTypeChange: useCallback((type: string) => {
      setFilterData((prev) => {
        const newFilterData = {
          ...prev,
          data: { ...prev.data, driveTypes: type },
        };

        return newFilterData;
      });
      setHasFilterChanged(true);
    }, []),

    handleRangeChange: useCallback(
      (field: string, value: number | undefined) => {
        setFilterData((prev) => {
          const newFilterData = {
            ...prev,
            data: {
              ...prev.data,
              [field]: value === undefined ? "" : value,
            },
          };

          return newFilterData;
        });
        setHasFilterChanged(true);
      },
      []
    ),

    handleRegionsChange: useCallback((regions: string[]) => {
      setFilterData((prev) => {
        const newFilterData = {
          ...prev,
          data: { ...prev.data, regions },
        };

        return newFilterData;
      });
      setHasFilterChanged(true);
    }, []),

    handleCitiesChange: useCallback((cities: string[]) => {
      setFilterData((prev) => {
        const newFilterData = {
          ...prev,
          data: { ...prev.data, cities },
        };

        return newFilterData;
      });
      setHasFilterChanged(true);
    }, []),

    handleSortChange: useCallback((field: string, order: "asc" | "desc") => {
      setFilterData((prev) => {
        const newFilterData = {
          ...prev,
          sort_metrics: {
            sort_fields: [{ field, order }],
          },
        };

        return newFilterData;
      });
      setHasFilterChanged(true);
    }, []),

    resetFilters: useCallback(() => {
      const newFilterData = {
        data: {
          carType: "all",
          isAccidentFree: false,
          isBelowMarket: false,
          isVat: false,
          carBody: [],
          transmission: [],
          radius: null,
          lastAdd: null,
          typeOfSeller: "",
          amountOfOwners: null,
          fuelTypes: "",
          driveTypes: "",
          mileageFrom: "",
          mileageTo: "",
          volumeFrom: "",
          volumeTo: "",
          powerFrom: "",
          powerTo: "",
          yearFrom: "",
          yearTo: "",
          regions: [],
          cities: [],
          from_ad: "",
          to_ad: "",
          brand_details: [
            { brand: "", models: [], generation: [0], custom_input: "" },
          ],
        },
        sort_metrics: {
          sort_fields: [{ field: "price", order: "asc" }],
        },
      };
      setFilterData(newFilterData);

      setHasFilterChanged(false);
    }, []),

    saveCurrentFilters: useCallback(
      (filterName: string) => {
        const newSavedFilter: SavedFilter = {
          name: filterName,
          data: filterData,
        };

        setSavedFilters((prev) => {
          const updated = [...prev, newSavedFilter];
          localStorage.setItem("savedCarFilters", JSON.stringify(updated));
          return updated;
        });
      },
      [filterData]
    ),

    loadSavedFilter: useCallback(
      (
        savedFilter: SavedFilter,
        handleCars?: Dispatch<SetStateAction<FilterData | null>>
      ) => {
        setFilterData(savedFilter.data);
        if (handleCars) {
          handleCars(savedFilter.data);
        }
      },
      []
    ),

    deleteSavedFilter: useCallback((filterName: string) => {
      setSavedFilters((prev) => {
        const updated = prev.filter((filter) => filter.name !== filterName);
        localStorage.setItem("savedCarFilters", JSON.stringify(updated));
        return updated;
      });
    }, []),

    handleBrandChange: useCallback((brand: string, index: number) => {
      setFilterData((prev) => {
        const newFilterData = {
          ...prev,
          data: {
            ...prev.data,
            brand_details: prev.data.brand_details.map((brandDetail, i) => {
              if (i === index) {
                return {
                  ...brandDetail,
                  brand,
                  models: [],
                  generation: [0],
                  custom_input: "",
                };
              }
              return brandDetail;
            }),
          },
        };

        return newFilterData;
      });
      setHasFilterChanged(true);
    }, []),

    handleModelsChange: useCallback(
      (selectedModels: string[], index: number) => {
        setFilterData((prev) => {
          const newFilterData = {
            ...prev,
            data: {
              ...prev.data,
              brand_details: prev.data.brand_details.map((brandDetail, i) => {
                if (i === index) {
                  return {
                    ...brandDetail,
                    models: selectedModels,
                  };
                }
                return brandDetail;
              }),
            },
          };

          return newFilterData;
        });
        setHasFilterChanged(true);
      },
      []
    ),

    handleGenerationsChange: useCallback(
      (generations: number[], index: number) => {
        setFilterData((prev) => {
          const newFilterData = {
            ...prev,
            data: {
              ...prev.data,
              brand_details: prev.data.brand_details.map((brandDetail, i) => {
                if (i === index) {
                  return {
                    ...brandDetail,
                    generation: generations,
                    custom_input: "",
                  };
                }
                return brandDetail;
              }),
            },
          };

          return newFilterData;
        });
        setHasFilterChanged(true);
      },
      []
    ),

    handleCustomInputChange: useCallback(
      (customInput: string, index: number) => {
        setFilterData((prev) => {
          const newFilterData = {
            ...prev,
            data: {
              ...prev.data,
              brand_details: prev.data.brand_details.map((brandDetail, i) => {
                if (i === index) {
                  return {
                    ...brandDetail,
                    custom_input: customInput,
                  };
                }
                return brandDetail;
              }),
            },
          };

          return newFilterData;
        });
        setHasFilterChanged(true);
      },
      []
    ),

    addBrandDetail: useCallback(() => {
      setFilterData((prev) => {
        const newFilterData = {
          ...prev,
          data: {
            ...prev.data,
            brand_details: [
              ...prev.data.brand_details,
              { brand: "", models: [], generation: [], custom_input: "" },
            ],
          },
        };

        return newFilterData;
      });
      setHasFilterChanged(true);
    }, []),

    removeBrandDetail: useCallback((index: number) => {
      setFilterData((prev) => {
        const newFilterData = {
          ...prev,
          data: {
            ...prev.data,
            brand_details: prev.data.brand_details.filter(
              (_, i) => i !== index
            ),
          },
        };

        return newFilterData;
      });
      setHasFilterChanged(true);
    }, []),
  };

  return {
    filterData,
    hasFilterChanged,
    savedFilters,
    brandsList,
    getModelsByBrand,
    handlers,
  };
}
