import styles from "./CarCard.module.css";
import { Sedan } from "shared/ui/Sedan/ui/Sedan";
import { Heart } from "shared/ui/Icons/Heart";
import { Stat } from "shared/ui/Icons/Stat";
import React, { useState, useEffect } from "react";
import { sendPatchRequest, sendDeleteRequest } from "shared/api/lib/requests";
import { useNavigate } from "react-router-dom";
import { colors } from "../model/constants";
import { CarCardProps } from "../model/interfaces";
import { useTranslation } from "react-i18next";

export function CarCard({
  date,
  title,
  displacement,
  transmission,
  price,
  mileage,
  inner_id,
  color,
  car_picture,
  year,
  shop_url,
  isFavorite,
}: CarCardProps) {
  const [isFav, setIsFav] = useState(isFavorite);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    setIsFav(isFavorite);
  }, [isFavorite]);

  const getFirstImage = () => {
    try {
      const parsedImages = JSON.parse(car_picture);
      if (Array.isArray(parsedImages) && parsedImages.length > 0) {
        return parsedImages[0].url;
      }
    } catch (error) {
      console.error("Error parsing image:", error);
    }
    return "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS-pKl40cUP8JzODO9dFP5U9KuTSQg1sr20dw&s";
  };

  const imageUrl = getFirstImage();

  const handleFavourite = async (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    try {
      if (isFav) {
        setIsFav(false);
        await sendDeleteRequest("cars-system/favourite", {
          access_token: localStorage.getItem("accessToken"),
          inner_id: inner_id,
        });
      } else {
        setIsFav(true);
        await sendPatchRequest("cars-system/favourite", {
          access_token: localStorage.getItem("accessToken"),
          inner_id: inner_id,
        });
      }
    } catch (error) {
      console.error("Error handling favourite:", error);
    }
  };

  const handleImageLoad = () => {
    setIsLoaded(true);
  };

  const handleStatClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    navigate(`/market/${inner_id}`);
  };

  return (
    <>
      <div
        className={styles.container}
        onClick={() => window.open(shop_url, "_blank")}
      >
        <div className={styles.carImageWrapper}>
          <img
            className={`${styles.carImage} ${isLoaded ? styles.loaded : ""}`}
            src={imageUrl}
            alt={title}
            onLoad={handleImageLoad}
          />
        </div>

        <div className={styles.dateInfo}>
          <p className={styles.boldText}>{date.time}</p>
          <p className={styles.smallText}>
            {date.day} {date.month}
          </p>
        </div>

        <div className={styles.titleInfo}>
          <p className={styles.carNameText}>{title.slice(0, 10)}...</p>
        </div>

        <div className={styles.yearInfo}>
          <p className={styles.boldText}>{year}</p>
        </div>

        <div className={styles.priceInfo}>
          <p className={styles.boldText}>€{price}</p>
        </div>

        <div className={styles.mileageInfo}>
          <p className={styles.boldText}>{mileage} km</p>
        </div>

        <div className={styles.engineInfo}>
          <p className={styles.boldText}>{t(transmission)}</p>
          <p className={styles.smallText}>
            {displacement} {t("liter")}
          </p>
        </div>

        <div className={styles.locationInfo}>
          <p className={styles.boldText}>{t("Berlin")}</p>
        </div>

        <div className={styles.icons}>
          <Sedan color={colors[color]} />
          <Heart onClick={handleFavourite} isFavourite={isFav} />
          <Stat onClick={handleStatClick} />
        </div>
      </div>

      <div
        className={styles.containerMobile}
        onClick={() => window.open(shop_url, "_blank")}
      >
        <img
          className={`${styles.carImageMobile} ${
            isLoaded ? styles.loaded : ""
          }`}
          src={imageUrl}
          alt={title}
          onLoad={handleImageLoad}
        />

        <div className={styles.infoMobile}>
          <div>
            <p className={styles.carNameTextMobile}>{title}</p>
            <div className={styles.fullInfoMobile}>
              <p className={styles.smallText}>
                {year} • {mileage} km
              </p>
              <p className={styles.smallText}>
                {t(transmission)} • {displacement}L
              </p>
              <p className={styles.smallText}>
                {date.day} {date.month}
              </p>
              <p className={styles.smallText}>{t("Berlin")}</p>
            </div>
          </div>

          <div className={styles.priceContainerMobile}>
            <p className={styles.priceMobile}>€{price}</p>
            <div className={styles.icons}>
              <Sedan color={colors[color]} />
              <Heart onClick={handleFavourite} isFavourite={isFav} />
              <Stat onClick={handleStatClick} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
