import { useTranslation } from "react-i18next";
import { CarsList } from "widgets/CarsList/ui/CarsList";
import styles from "./FavouritesPage.module.css";
import { useFavoriteContext } from "contexts/FavoriteContext";
import { useState, useEffect } from "react";
import { SortField } from "features/СarFilters/model/interfaces";

export function FavouritesPage() {
  const { t } = useTranslation();
  const { favoriteList } = useFavoriteContext();
  const [loading, setLoading] = useState(true);
  const [currentSort, setCurrentSort] = useState<{
    field: SortField["field"];
    order: SortField["order"];
  }>({
    field: "price",
    order: "asc",
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const handleSort = (field: SortField["field"], order: SortField["order"]) => {
    setCurrentSort({ field, order });
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>{t("favorite")}</h1>
      <CarsList
        cars={favoriteList}
        onSort={handleSort}
        currentSort={currentSort}
        loading={loading}
      />
    </div>
  );
}
