import styles from "./CardSkeleton.module.css";

export function CardSkeleton() {
  return (
    <>
      <div className={styles.container}>
        <div className={`${styles.skeleton} ${styles.image}`} />
        <div className={styles.dateWrapper}>
          <div className={`${styles.skeleton} ${styles.time}`} />
          <div className={`${styles.skeleton} ${styles.date}`} />
        </div>
        <div className={`${styles.skeleton} ${styles.title}`} />
        <div className={`${styles.skeleton} ${styles.year}`} />
        <div className={`${styles.skeleton} ${styles.price}`} />
        <div className={`${styles.skeleton} ${styles.mileage}`} />
        <div className={styles.engineWrapper}>
          <div className={`${styles.skeleton} ${styles.transmission}`} />
          <div className={`${styles.skeleton} ${styles.engine}`} />
        </div>
        <div className={`${styles.skeleton} ${styles.location}`} />
        <div className={styles.iconsWrapper}>
          <div className={`${styles.skeleton} ${styles.icon}`} />
          <div className={`${styles.skeleton} ${styles.icon}`} />
          <div className={`${styles.skeleton} ${styles.icon}`} />
        </div>
      </div>

      <div className={styles.containerMobile}>
        <div className={`${styles.skeleton} ${styles.imageMobile}`} />
        <div className={styles.infoMobile}>
          <div className={`${styles.skeleton} ${styles.titleMobile}`} />
          <div className={styles.detailsGrid}>
            <div className={`${styles.skeleton} ${styles.detail}`} />
            <div className={`${styles.skeleton} ${styles.detail}`} />
            <div className={`${styles.skeleton} ${styles.detail}`} />
            <div className={`${styles.skeleton} ${styles.detail}`} />
          </div>
          <div className={styles.footerMobile}>
            <div className={`${styles.skeleton} ${styles.priceMobile}`} />
            <div className={styles.iconsMobile}>
              <div className={`${styles.skeleton} ${styles.icon}`} />
              <div className={`${styles.skeleton} ${styles.icon}`} />
              <div className={`${styles.skeleton} ${styles.icon}`} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
