export function Heart({
  isFavourite,
  onClick,
}: {
  isFavourite: boolean;
  onClick: (e: any) => void;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="18"
      viewBox="0 0 20 18"
      onClick={onClick}
      style={{ cursor: "pointer" }}
    >
      <g
        fill={isFavourite ? "#FF6F02" : "none"}
        fillRule="evenodd"
        stroke="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      >
        <g
          stroke="#929293"
          strokeWidth="1.25"
          transform="translate(-1229 -780)"
        >
          <g transform="translate(110 679)">
            <path d="M1129.096 117.413l-7.707-7.136c-4.2-4.223 1.927-12.4 7.707-5.785 5.78-6.595 11.964 1.581 7.706 5.785l-7.706 7.136z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
