import { useEffect, useState } from "react";
import styles from "./RegistrationSteps.module.css";
import { Button } from "shared/ui/button";
import { useNavigate } from "react-router-dom";
import { InputWithButton } from "shared/ui/InputWithButton";
import { sendPostRequest } from "shared/api";
import { useTranslation } from "react-i18next";

// Добавляем интерфейс для ответа авторизации
interface AuthResponse {
  data: {
    sign_key: string;
    access_token?: string;
  };
  exception?: number;
  message?: string;
}

// Типизируем пропсы для LoginHeader
interface LoginHeaderProps {
  stage: "phone" | "phoneCode" | "end";
}

// Компонент для отображения заголовка на каждой стадии
function LoginHeader({ stage }: LoginHeaderProps) {
  const { t } = useTranslation();
  const [text, setText] = useState<string>("");

  useEffect(() => {
    switch (stage) {
      case "phone":
        setText(t("enterCarAii"));
        break;
      case "phoneCode":
        setText(t("enterCode"));
        break;
      case "end":
        setText(t("successEnter"));
        break;
      default:
        setText("");
    }
  }, [stage, t]);

  return <h1 className={styles.headerText}>{text}</h1>;
}

// Основной компонент для отображения шагов регистрации
export function RegistrationSteps() {
  const { t } = useTranslation();
  const [stage, setStage] = useState<"phone" | "phoneCode" | "end">("phone"); // текущая стадия ('phone', 'phoneCode', 'end')
  const [signKey, setSignKey] = useState<string>(""); // ключ для подтверждения после отправки номера
  const [phone, setPhone] = useState<string>(""); // введённый номер телефона
  const [code, setCode] = useState<string>(""); // введённый код подтверждения
  const [errorMessage, setErrorMessage] = useState<string>(""); // состояние для отображения ошибки
  const navigate = useNavigate(); // навигация для перехода на другие страницы

  // Отправляем запрос с номером телефона для авторизации
  async function handlePhoneAuth(): Promise<void> {
    try {
      const response = await sendPostRequest<AuthResponse>("auth/login", {
        phone: phone,
        send_message_type: "WhatsApp", // тип отправки сообщения (например, через WhatsApp)
      });

      if (response?.data?.sign_key) {
        setStage("phoneCode");
        setSignKey(response.data.sign_key); // сохраняем ключ для подтверждения
      }
    } catch (error) {
      console.error("Ошибка при отправке номера телефона:", error);
      setErrorMessage(t("errorPhone"));
    }
  }

  // Отправляем запрос с кодом для подтверждения входа
  async function handlePhoneSubmit(): Promise<void> {
    if (!code) {
      setErrorMessage(t("errorEmptyCode"));
      return;
    }

    try {
      const response = await sendPostRequest<AuthResponse>(
        "auth/confirm-login",
        {
          sign_key: signKey,
          verify_code: code,
        }
      );

      if (response?.exception === 666) {
        setErrorMessage(response?.message || t("errorInvalidCode"));
      } else if (response?.data?.access_token) {
        localStorage.setItem("accessToken", response.data.access_token);
        setStage("end");
      }
    } catch (error) {
      console.error("Ошибка при подтверждении кода:", error);
      setErrorMessage(t("errorCode"));
    }
  }

  return (
    <div className={styles.wrapper}>
      {stage !== "end" ? (
        <div className={styles.container}>
          <div className={styles.progress}>
            <div
              className={`${styles.step} ${
                stage === "phone" && styles.currentStep
              }`}
            ></div>
            <div
              className={`${styles.step} ${
                stage === "phoneCode" && styles.currentStep
              }`}
            ></div>
            <div
              className={`${styles.step} ${
                stage === "phoneCode" && styles.currentStep
              }`}
            ></div>
          </div>
          <LoginHeader stage={stage} />
          <div className={styles.content}>
            {stage === "phone" ? (
              <InputWithButton
                placeholder={t("phoneNumber")}
                buttonText={t("continue")}
                onChange={setPhone}
                value={phone}
                isPhoneInput={true}
                onSubmit={handlePhoneAuth}
              />
            ) : (
              stage === "phoneCode" && (
                <>
                  <div className={styles.phoneNumberContainer}>
                    <span>
                      {t("phoneDisplay")
                        .replace("{areaCode}", phone.slice(1, 4))
                        .replace("{firstPart}", phone.slice(4, 7))
                        .replace("{secondPart}", phone.slice(7, 9))
                        .replace("{thirdPart}", phone.slice(9, 12))}
                    </span>
                    <button
                      onClick={() => setStage("phone")}
                      className={styles.changeButton}
                    >
                      {t("change")}
                    </button>
                  </div>
                  <InputWithButton
                    onChange={setCode}
                    value={code}
                    placeholder={t("submitCode")}
                    buttonText={t("login")}
                    onSubmit={handlePhoneSubmit}
                    isPhoneInput={false}
                  />
                  {errorMessage && (
                    <div className={styles.errorMessage}>{errorMessage}</div>
                  )}
                </>
              )
            )}
          </div>
        </div>
      ) : (
        <div className={styles.container}>
          <div className={styles.endRegistration}>
            <LoginHeader stage={stage} />
            <Button onClick={() => navigate("/")} className={styles.endButton}>
              {t("goToSite")}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
