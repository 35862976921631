import styles from "./Input.module.css";

interface InputProps {
  placeholder?: string; // Optional placeholder text for the input
  onChange?: React.ChangeEventHandler<HTMLInputElement>; // Optional change event handler
  width?: string; // Optional width, defaults to "100%"
  flexGrow?: string; // Optional flex-grow CSS property, defaults to "1"
  value?: any;
  disabled?: boolean;
}

export function Input({
  placeholder,
  onChange,
  width = "100%",
  flexGrow = "1",
  value,
  disabled = false,
}: InputProps) {
  return (
    <input
      disabled={disabled}
      value={value}
      className={styles.input}
      style={{ width: width, flexGrow: flexGrow }}
      placeholder={placeholder}
      onChange={onChange}
    />
  );
}
