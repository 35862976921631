import React, {
  useState,
  useContext,
  createContext,
  ReactNode,
  useEffect,
} from "react";

// Define the context type
interface FavoriteContextType {
  favoriteList: any[]; // Замените `any` на конкретный тип, если известно
  setFavoriteList: React.Dispatch<React.SetStateAction<any[]>>;
}

// Create the context with the defined type
const Context = createContext<FavoriteContextType | null>(null);

// Create the provider component
export const FavoriteContextProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [favoriteList, setFavoriteList] = useState<any[]>([]);

  // Загружаем данные из localStorage при первом рендере
  useEffect(() => {
    const storedFavorites = localStorage.getItem("favoriteList");
    if (storedFavorites) {
      try {
        const parsedFavorites = JSON.parse(storedFavorites);
        if (Array.isArray(parsedFavorites)) {
          setFavoriteList(parsedFavorites); // Если это массив, устанавливаем его
        } else {
          setFavoriteList([]); // Если нет, устанавливаем пустой массив
        }
      } catch (error) {
        console.error("Ошибка при парсинге избранных:", error);
        setFavoriteList([]); // В случае ошибки, устанавливаем пустой массив
      }
    }
  }, []);

  // Сохраняем в localStorage при изменении favoriteList
  useEffect(() => {
    localStorage.setItem("favoriteList", JSON.stringify(favoriteList));
  }, [favoriteList]);

  const value: FavoriteContextType = {
    favoriteList,
    setFavoriteList,
  };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

// Create the custom hook to use the context
export function useFavoriteContext() {
  const context = useContext(Context);
  if (!context) {
    throw new Error(
      "useFavoriteContext must be used within a FavoriteContextProvider"
    );
  }
  return context;
}
