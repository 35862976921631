import { MainPageProvider } from "contexts/MainPageContext";
import { AutoNameSelector } from "widgets/AutoNameSelector";
import { CarShowcase } from "widgets/CarShowcase";
import styles from "./MainPage.module.css";
import useAddressStore from "store/useAdressStore";
import { useEffect } from "react";

export function MainPage() {
  const { addresses, fetchAddresses, loadFromCache } = useAddressStore();

  useEffect(() => {
    loadFromCache();
    if (!addresses) {
      fetchAddresses();
    }
  }, []);

  return (
    <MainPageProvider>
      <div className={styles.container}>
        <AutoNameSelector />
        <CarShowcase />
      </div>
    </MainPageProvider>
  );
}
